export class User {
  constructor(
    public id: number = 0,
    public firstname: string = '',
    public lastname: string = '',
    public document: string = '',
    public phone: string = '',
    public email: string = '',
    public password: string = '',
    public country_id: number = 0,
    public state_id: number = 0,
    public city_id: number = 0,
    public zipcode: number = 0,
    public address: string = '',
    public agreement: boolean = false,
    public dataAgreement: boolean = false

  ) {
  }
}
