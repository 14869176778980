import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs/operators';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  configService: ConfigService;

  constructor(public http: HttpClient) {
    this.configService = new ConfigService();
  }

  getServicesByUser() {//
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const params = new HttpParams().set('user_id', decodedToken.sub);
    return this.http.get(this.configService.serverIp.concat('/userServices'), { headers, params });
  }

  getServicesByUserId(user_id) {//
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const params = new HttpParams().set('user_id', user_id);
    return this.http.get(this.configService.serverIp.concat('/userServices'), { headers, params });
  }

  getServicesByCountry(location_id) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const params = new HttpParams().set('user_id', decodedToken.sub).append('location_id', location_id);
    return this.http.get(this.configService.serverIp.concat('/userServicesByLocation'), { headers, params });
  }

  getServicesByUserLocation(user_id, location_id) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const params = new HttpParams().set('user_id', user_id).append('location_id', location_id);
    return this.http.get(this.configService.serverIp.concat('/userServicesByLocation'), { headers, params });
  }

  getServicesForCalculator(country_id) {
    const token = localStorage.getItem('currentUser');
    if (token) {
      const headers = new HttpHeaders({
        'authorization': token
      });
      const params = new HttpParams().set('country_id', country_id);
      return this.http.get(this.configService.serverIp.concat('/listServiceByCountry'), { headers, params });
    } else {
      const headers = new HttpHeaders({});
      const params = new HttpParams().set('country_id', country_id);
      return this.http.get(this.configService.serverIp.concat('/listServiceByCountry'), {headers,  params });
    }
  }

  getEstimated(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/estimatedConsolidatedPrice'), data, { headers });
  }

  getEstimatedGuideAgency(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/estimatedPriceAgency'), data, { headers });
  }

  getPoundValue(service, userId) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = new HttpParams().set('service', service).set('userId', userId);
    return this.http.get(this.configService.serverIp.concat('/poundValue'), { headers, params });
  }

  getServicesToEditGuide(guide,country_id) {
    let params = new HttpParams();
    params = params.append('guide', guide);
    params = params.append('country_id', country_id);
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/getServicesToEditGuide'), { headers, params });

  }

  getServicesToEditGuideMaster(guide,country_id) {
    let params = new HttpParams();
    params = params.append('guide', guide);
    params = params.append('country_id', country_id);
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/getServicesToEditGuideMaster'), { headers, params });

  }

  getEstimatePricesToEditGuide(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/estimatePricesToEditGuide'), data, { headers });
  }
   
   getAllServiceTemplate(): Observable<any[]> {
    const url = this.configService.serverIp.concat('/getTemplateService');
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    
    return this.http.get<any>(url,{headers})
    .pipe(map((service: any[]) => {
      return service;
    }));
  }

  getAllAsFormArray(): Observable<FormArray> {
    return this.getAllServiceTemplate().pipe(map((service: any[]) => {
     
      const fgs=service['services'].map(m=>{
        const fg= new FormGroup({
          id: new FormControl(m.id, Validators.compose([])),
          name: new FormControl(m.name, Validators.compose([])),
          nameTemplate: new FormControl(m.nameTemplate, Validators.compose([Validators.maxLength(200)])),
        });
        return fg;
      });

      return new FormArray(fgs);
    }));
  }


  updateTemplateService(data){
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/updateTemplateService'), data, { headers });
  }

 
}
