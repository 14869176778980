import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, } from "@angular/common/http";
import { ConfigService } from "./config.service";
import { Observable } from "rxjs/internal/Observable";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})

export class RegisterService {
  configService: ConfigService;

  constructor(public http: HttpClient) {
    this.configService = new ConfigService();
    //////console.log('esta es la ip: ',this.configService);
  }

  postRegister(data) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(this.configService.serverIp.concat('/register'), data, { headers });
  }

  getCountries(): Observable<any> {
    return this.http.get(this.configService.serverIp.concat('/register'));
  }

  getCountriesWithMeasures(): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/countriesWithMeasures'), { headers });
  }

  getStatesByCountry(countryId) {
    const params = new HttpParams().set('country_id', countryId);
    return this.http.get(this.configService.serverIp.concat('/statesbycountry'), { params });
  }

  getCitiesByState(stateId) {
    const params = new HttpParams().set('state_id', stateId);
    return this.http.get(this.configService.serverIp.concat('/citiesbystate'), { params });
  }

  recaptchaValidation(response: string) {
    const secret_key = "6LeMGNAUAAAAAFbZlr7OJyqXKEaALguR_irnmES-";
    return this.http.post("https://www.google.com/recaptcha/api/siteverify", {
      'secret': secret_key,
      'response': response
    });
  }
}
