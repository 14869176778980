import { Component, HostBinding, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';

@Component({
    // moduleId: module.id,
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
    currentDate: Date = new Date();
    socialMediaInfo = [];
    constructor(private socialMedia: LoginService){

    }

    ngOnInit(){
        this.getSocialMedia();
    }


    getSocialMedia() {
        this.socialMedia.getSocialMedia().subscribe( res => {
           
            this.socialMediaInfo = res['socialMedia'];
          
        });
    }
}
