import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';
import { MultilingualHeaderImageService } from 'src/app/services/multilingual-header-image.service';
import { MatTableDataSource } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GuideStatusService } from 'src/app/services/guide-status.service';
import { element } from '@angular/core/src/render3/instructions';
import {AlertMessageService} from '../../services/alert-message.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-search-guide-status',
  templateUrl: './search-guide-status.component.html',
  styleUrls: ['./search-guide-status.component.scss']
})
export class SearchGuideStatusComponent implements OnInit {

  guideStatus: any;
  status: any;
  searchForm: FormGroup;
  dataTable: any;
  guide: any;
  carrierStatus: any;

  constructor(private multilingualHeadersService: MultilingualHeaderImageService,
    private toastr: ToastrService,
    private languageService: LanguageService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private alertMessageService: AlertMessageService,
    private guide_service: GuideStatusService) {
    this.languageService.initLanguageConfig();
    this.translate.use(this.languageService.getCurrentLanguage());
  }

  ngOnInit() {
    this.searchForm = this.formBuilder.group({
      'guideNumber': ['', Validators.required]
    });
  }

  over() {
    console.log('Mouseover called');
  }
  getHeaderURL() {
    const language = this.languageService.getCurrentLanguage();
    return this.multilingualHeadersService.getHeaderURL('15', language);
  }

  getFlagURL(): string {
    const language = this.languageService.getCurrentLanguage();
    return this.languageService.getLanguageFlagURL(language);
  }

  getGuideStatus() {
    this.guide = this.searchForm.controls['guideNumber'].value;
    this.guide_service.searchStatusGuide(this.guide).subscribe(result => {
      if (result['status']) {
        this.status = result['states'];
        this.status = this.status.filter((element) => !element.hide);
        this.guideStatus = new MatTableDataSource(this.status.slice(0).reverse());
      }
    }
    );
  }

  getCarrierStatus() {
    this.guide_service.searchCarrierStatusGuide(this.guide).subscribe(result => {
      if (result['status']) {
        if (result['states']) {
          this.carrierStatus = result['states'];
          let statusAux = this.carrierStatus.concat(this.status.slice(0).reverse());
          this.guideStatus = new MatTableDataSource(statusAux.slice(0));
        } else if (result['url']) {
          window.open(result['url'], '_blank');
        } else if (result['message']) {
          this.toastr.success(result['message']);
        }
      } else {
        const language = this.languageService.getCurrentLanguage();
        const messageCode = result['code'];
        this.alertMessageService.showErrorMessage(language, messageCode);
      }
    }
    );
  }

  // Método para cambiar el lenguaje de la aplicación
  ChangeLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('language', language);
  }

}
