import { Injectable } from '@angular/core';

@Injectable()

export class ConfigService {
 //serverIp = 'http://192.168.1.65:1337';
 serverIp = 'https://api-comprasporti.logisticscargo.net';
  constructor() {}
}
// http://192.168.0.24:1337
//https://api.comprasporti.logisticscargo.net
//https://api.deltoroexpress.logisticscargo.net
//https://api.demo.logisticscargo.net
//https://api.eaglefreight.logisticscargo.net
//https://api.usanos.logisticscargo.net
//https://api.tuencomienda.logisticscargo.net
