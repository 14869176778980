import { Routes } from '@angular/router';
export const Full_ROUTES: Routes = [

    // Aquí se pueden encontrar todas las rutas del sistema. ctrl + f para buscar en
    // el documento actual y ctrl + p para buscar el componente en el folder.

    {
        path: 'home',
        loadChildren: './pages/home-page/home-pages.module#HomePagesModule'
    },
    {
        path: 'pre-alert',
        loadChildren: './forms/pre-alert/pre-alert.module#PreAlertModule'
    },
    {
        path: 'pre-alert-packages',
        loadChildren: './forms/pre-alert-packages/pre-alert-packages.module#PreAlertPackagesModule'
    },
    {
        path: 'create-operator',
        loadChildren: './forms/create-warehouse-operator/create-warehouse-operator.module#CreateWarehouseOperatorModule'
    },
    {
        path: 'register-package',
        loadChildren: './forms/register-pre-alert-package/register-pre-alert-package.module#RegisterPreAlertPackageModule'
    },
    {
        path: 'warehouse-packages',
        loadChildren: './forms/warehouse-packages/warehouse-packages.module#WarehousePackagesModule'
    },
    {
        path: 'consolidate-packages',
        loadChildren: './forms/consolidate-packages/consolidate-packages.module#ConsolidatePackagesModule'
    },
    {
        path: 'register-arrived-package',
        loadChildren: './forms/register-arrived-package/register-arrived-package.module#RegisterArrivedPackageModule'
    },
    {
        path: 'sent-packages-client',
        loadChildren: './forms/sent-packages-client/sent-packages-client.module#SentPackagesClientModule'
    },
    {
        path: 'pending-cod-payment',
        loadChildren: './forms/pending-cod-payment/pending-cod-payment.module#PendingCodPaymentModule'
    },
    {
        path: 'list-operators',
        loadChildren: './forms/list-operators/list-operators.module#ListOperatorsModule'
    },
    {
        path: 'edit-operator/:id',
        loadChildren: './forms/edit-operator/edit-operator.module#EditOperatorModule'
    },
    {
        path: 'change-password',
        loadChildren: './forms/change-password/change-password.module#ChangePasswordModule'
    },
    {
        path: 'consolidated-packages',
        loadChildren: './forms/consolidated-packages/consolidated-packages.module#ConsolidatedPackagesModule'
    },
    {
        path: 'dispatch-shipment',
        loadChildren: './forms/dispatch-shipment/dispatch-shipment.module#DispatchShipmentModule'
    },
    {
        path: 'dispatch-shipment-to-city',
        loadChildren: './forms/dispatch-shipment-to-city/dispatch-shipment-to-city.module#DispatchShipmentToCityModule'
    },
    {
        path: 'boxes-in-customs',
        loadChildren: './forms/boxes-in-customs/boxes-in-customs.module#BoxesInCustomsModule'
    },
    {
        path: 'create-shop',
        loadChildren: './forms/create-shop/create-shop.module#CreateShopModule'
    },
    {
        path: 'create-carrier',
        loadChildren: './forms/create-carrier/create-carrier.module#CreateCarrierModule'
    },
    {
        path: 'create-service',
        loadChildren: './forms/create-service/create-service.module#CreateServiceModule'
    },
    {
        path: 'list-shops',
        loadChildren: './forms/list-shops/list-shops.module#ListShopsModule'
    },
    {
        path: 'edit-shop/:id',
        loadChildren: './forms/edit-shop/edit-shop.module#EditShopModule'
    },
    {
        path: 'list-carriers',
        loadChildren: './forms/list-carriers/list-carriers.module#ListCarriersModule'
    },
    {
        path: 'edit-carrier/:id',
        loadChildren: './forms/edit-carrier2/edit-carrier.module#EditCarrierModule'
    },
    {
        path: 'create-wr-with-sender',
        loadChildren: './forms/create-wr-with-sender/create-wr-with-sender.module#CreateWrWithSenderModule'
    },
    {
        path: 'list-locker-users',
        loadChildren: './forms/list-locker-users/list-locker-users.module#ListLockerUsersModule'
    },
    {
        path: 'list-clients-agency',
        loadChildren: './forms/list-clients-agency/list-clients-agency.module#ListClientsAgencyModule'
    },
    {
        path: 'edit-costs-by-user/:id',
        loadChildren: './forms/edit-costs-by-user/edit-costs-by-user.module#EditCostsByUserModule'
    },
    {
        path: 'edit-services-by-user/:id',
        loadChildren: './forms/edit-services-by-user/edit-services-by-user.module#EditServicesByUserModule'
    },
    {
        path: 'edit-service-agency-user/:id',
        loadChildren: './forms/edit-service-agency-user/edit-service-agency-user.module#EditServiceAgencyUserModule'
    },
    {
        path: 'dispatched-containers',
        loadChildren: './forms/dispatched-containers/dispatched-containers.module#DispatchedContainersModule'
    },
    {
        path: 'list-wr',
        loadChildren: './forms/list-wr/list-wr.module#ListWrModule'
    },
    {
        path: 'list-services',
        loadChildren: './forms/list-services/list-services.module#ListServicesModule'
    },
    {
        path: 'edit-service/:id',
        loadChildren: './forms/edit-service/edit-service.module#EditServiceModule'
    },
    {
        path: 'edit-company-info',
        loadChildren: './forms/edit-company-info/edit-company-info.module#EditCompanyInfoModule'
    },
    {
        path: 'consolidated-packages-operator',
        loadChildren: './forms/consolidated-packages-operator/consolidated-packages-operator.module#ConsolidatedPackagesOperatorModule'
    },
    {
        path: 'requested-shipments',
        loadChildren: './forms/requested-shipments/requested-shipments.module#RequestedShipmentsModule'
    },
    {
        path: 'edit-profile',
        loadChildren: './forms/edit-profile/edit-profile.module#EditProfileModule'
    },
    {
        path: 'package-tracker',
        loadChildren: './forms/package-tracker/package-tracker.module#PackageTrackerModule'
    },
    {
        path: 'list-duty-taxes',
        loadChildren: './forms/list-duty-taxes/list-duty-taxes.module#ListDutyTaxesModule'
    },
    {
        path: 'create-duty-tax',
        loadChildren: './forms/create-duty-taxes/create-duty-tax.module#CreateDutyTaxModule'
    },
    {
        path: 'edit-duty-tax/:id',
        loadChildren: './forms/edit-duty-tax/edit-duty-tax.module#EditDutyTaxModule'
    },
    {
        path: 'view-service/:id',
        loadChildren: './forms/view-service/view-service.module#ViewServiceModule'
    },
    {
        path: 'view-user-service/:id',
        loadChildren: './forms/view-user-service/view-user-service.module#ViewUserServiceModule'
    },
    {
        path: 'list-users-agency/:id',
        loadChildren: './forms/list-users-agency/list-users-agency.module#ListUsersAgencyModule'
    },
    {
        path: 'create-user-agency/:id',
        loadChildren: './forms/create-user-agency/create-user-agency.module#CreateUserAgencyModule'
    },
    {
        path: 'create-user-agency',
        loadChildren: './forms/create-user-from-agency/create-user-from-agency.module#CreateUserFromAgencyModule'
    },
    {
        path: 'calculator',
        loadChildren: './forms/calculator/calculator.module#CalculatorModule'
    },
    {
        path: 'my-addresses',
        loadChildren: './forms/my-addresses/my-addresses.module#MyAddressesModule'
    },
    {
        path: 'pickup-requests',
        loadChildren: './forms/pickup-requests/pickup-requests.module#PickupRequestsModule'
    },
    {
        path: 'register-wr',
        loadChildren: './forms/register-wr/register-wr.module#RegisterWrModule'
    },
    {
        path: 'add-storehouse',
        loadChildren: './forms/add-storehouse/add-storehouse.module#AddStorehouserModule'
    },
    {
        path: 'list-storehouse',
        loadChildren: './forms/list-storehouse/list-storehouse.module#ListStorehouseModule'
    },
    {
        path: 'list-storage-type',
        loadChildren: './forms/list-storage-type/list-storage-type.module#ListStorageTypeModule'
    },
    {
        path: 'add-storage-type',
        loadChildren: './forms/add-storage-type/add-storage-type.module#AddStorageTypeModule'
    },
    {
        path: 'edit-storehouse/:id',
        loadChildren: './forms/edit-storehouse/edit-storehouse.module#EditStorehouseModule'
    },
    {
        path: 'edit-storage-type/:id',
        loadChildren: './forms/edit-storage-type/edit-storage-type.module#EditStorageTypeModule'
    },
    {
        path: 'list-all-pending-payment',
        loadChildren: './forms/list-pending-cod-payment/list-pending-cod-payment.module#ListPendingCodPaymentRoutingModule'
    },
    {
        path: 'wr-to-awb',
        loadChildren: './forms/wr-to-awb/wr-to-awb.module#WrToAwbModule'
    },
    {
        path: 'create-agency',
        loadChildren: './forms/create-agency/create-agency.module#CreateAgencyModule'
    },
    {
        path: 'list-agencies',
        loadChildren: './forms/list-agencies/list-agencies.module#ListAgenciesModule'
    },
    {
        path: 'edit-agency/:id',
        loadChildren: './forms/edit-agency/edit-agency.module#EditAgencyModule'
    },
    {
        path: 'create-guide-agency',
        loadChildren: './forms/create-guide-agency/create-guide-agency.module#CreateGuideAgencyModule'
    },
    {
        path: 'create-guide-agency-master',
        loadChildren: './forms/create-guide-agency-master/create-guide-agency-master.module#CreateGuideAgencyMasterModule'
    },
    {
        path: 'create-guide-for-agency',
        loadChildren: './forms/create-guide-for-agency/create-guide-for-agency.module#CreateGuideForAgencyModule'
    },
    {
        path: 'edit-modules',
        loadChildren: './forms/edit-modules/edit-modules.module#EditModulesModule'
    },
    {
        path: 'list-guides-agency',
        loadChildren: './forms/list-guides-agency/list-guides-agency.module#ListGuidesAgencyModule'
    },
    {
        path: 'list-pickup-agency',
        loadChildren: './forms/list-pickups-agency/list-pickups-agency.module#ListPickupsAgencyModule'
    },
    {
        path: 'edit-services-by-agency/:id',
        loadChildren: './forms/edit-services-by-agency/edit-services-by-agency.module#EditServicesByAgencyModule'
    },
    {
        path: 'checkout-wr',
        loadChildren: './forms/checkout-wr/checkout-wr.module#CheckoutWrModule'
    },
    {
        path: 'list-consolidated',
        loadChildren: './forms/list-awb/list-awb.module#ListAwbModule'
    },
    {
        path: 'edit-payment-credentials',
        loadChildren: './forms/edit-payment-credentials/edit-payment-credentials.module#EditPaymentCredentialsModule'
    },
    {
        path: 'dollar-values',
        loadChildren: './forms/dollar-values/dollar-values.module#DollarValuesModule'
    },
    {
        path: 'consolidated-guides/:id',
        loadChildren: './forms/edit-awb/edit-awb.module#EditAwbModule'
    },
    {
        path: 'guide-adjustment',
        loadChildren: './forms/guide-adjustment/guide-adjustment.module#GuideAdjustmentModule'
    },
    {
        path: 'create-awb',
        loadChildren: './forms/create-awb/create-awb.module#CreateAwbModule'
    },
    {
        path: 'create-carrier-awb',
        loadChildren: './forms/create-carrier-awb/create-carrier-awb.module#CreateCarrierAwbModule'
    },
    {
        path: 'list-carrier-awb',
        loadChildren: './forms/list-carrier-awb/list-carrier-awb.module#ListCarriersAwbModule'
    },
    {
        path: 'edit-carrier-awb/:id',
        loadChildren: './forms/edit-carrier-awb/edit-carrier-awb.module#EditCarrierAwbModule'
    },
    {
        path: 'create-guide-status',
        loadChildren: './forms/create-guide-status/create-guide-status.module#CreateGuideStatusModule'
    },
    {
        path: 'list-guide-status',
        loadChildren: './forms/list-guide-status/list-guide-status.module#ListGuideStatusModule'
    },
    {
        path: 'edit-guide-status/:id',
        loadChildren: './forms/edit-guide-status/edit-guide-status.module#EditGuideStatusModule'
    },
    {
        path: 'pick-up-guide-from-agency',
        loadChildren: './forms/pick-up-guide-from-agency/pick-up-guide-from-agency.module#PickUpGuideFromAgencyModule'
    },
    {
        path: 'country-origin-arrival-winery',
        loadChildren: './forms/country-origin-arrival-winery/country-origin-arrival-winery.module#CountryOriginArrivalWineryModule'
    },
    {
        path: 'list-guides',
        // tslint:disable-next-line: max-line-length
        loadChildren: './forms/list-guides-operator-country-origin/list-guides-operator-country-origin.module#ListGuidesOperatorCountryOriginModule'
    },
    {
        path: 'list-guides-destiny-country',
        // tslint:disable-next-line: max-line-length
        loadChildren: './forms/list-guides-operator-country-destiny/list-guides-operator-country-destiny.module#ListGuidesOperatorCountryDestinyModule'
    },
    {
        path: 'details-of-guide/:guideNumber',
        loadChildren: './forms/details-of-guide/details-of-guide.module#DetailsOfGuideModule'
    },
    {
        path: 'details-of-guide-from-user/:guideNumber',
        loadChildren: './forms/details-of-guide-from-user/details-of-guide-from-user.module#DetailsOfGuideFromUserModule'
    },
    {
        path: 'register-guide-destiny-country',
        loadChildren: './forms/register-guide-destiny-country/register-guide-destiny-country.module#RegisterGuideDestinyCountryModule'
    },
    {
        path: 'guides-to-charge-destiny-country',
        loadChildren: './forms/guides-to-charge-destiny-country/guide-to-charge-destroy-country.module#GuidesToChargeDestinyCountryModule'
    },
    {
        path: 'guides-dispatch-destiny-country',
        loadChildren: './forms/list-guides-dispatch-country-destiny/list-guides-dispatch-country-destiny.module#ListGuidesDispatchCountryDestinyModule'
    },
    {
        path: 'guides-to-delivering-local',
        loadChildren: './forms/guides-to-delivering-local/guide-to-delivering-local.module#GuidesToDeliveringLocalModule'
    },
    {
        path: 'agency-statistics/:id',
        loadChildren: './forms/administrative-report-agency/administrative-report-agency.module#AdministrativeReportAgencyModule'
    },
    {
        path: 'agencies-monitor',
        loadChildren: './forms/administrative-report/administrative-report.module#AdministrativeReportModule'
    },
    {
        path: 'list-routes',
        loadChildren: './forms/list-routes/list-routes.module#ListRoutesModule'
    },
    {
        path: 'create-route',
        loadChildren: './forms/create-route/create-route.module#CreateRouteModule'
    },
    {
        path: 'edit-route/:id',
        loadChildren: './forms/edit-route/edit-route.module#EditRouteModule'
    },
    {
        path: 'list-guides-warehouse',
        loadChildren: './forms/list-guides-warehouse/list-guides-warehouse.module#ListGuidesWarehouseModule'
    },
    {
        path: 'user-report',
        loadChildren: './forms/user-report/user-report.module#UserReportModule'
    },
    {
        path: 'prealert-report',
        loadChildren: './forms/prealert-report/prealert-report.module#PrealertReportModule'
    },
    {
        path: 'packages-warehouse-report',
        loadChildren: './forms/packages-in-wrt-report/packages-in-wrt-report.module#PackagesInWrtReportModule'
    },
    {
        path: 'guides-report',
        loadChildren: './forms/guides-report/guides-report.module#GuidesReportModule'
    },
    {
        path: 'generate-delivery-report',
        loadChildren: './forms/list-reports-agency/list-reports-agency.module#ListReportsAgencyModule'
    },
    {
        path: 'create-scale',
        loadChildren: './forms/create-scale/create-scale.module#CreateScaleModule'
    },
    {
        path: 'report-agency',
        loadChildren: './forms/report-agency/report-agency.module#ReportAgencyModule'
    },

    {
        path: 'report-loker',
        loadChildren: './forms/report-loker/report-loker.module#ReportLokerModule'
    },
    {
        path: 'list-scales',
        loadChildren: './forms/list-scales/list-scales.module#ListScalesModule'
    },
    {
        path: 'measures-required',
        loadChildren: './forms/create-measures-required/create-measures-required.module#CreateMeasuresRequiredModule'
    },
    {
        path: 'countries-with-measures',
        loadChildren: './forms/list-countries-with-measures/list-countries-with-measures.module#ListCountriesWithMeasuresModule'
    },
    {
        path: 'edit-guide',
        loadChildren: './forms/edit-guide/edit-guide.module#EditGuideModule'
    },
    // {
    //     path: 'edit-guide/:guideNumber',
    //     loadChildren: './forms/edit-guide/edit-guide.module#EditGuideModule'
    // },
    {
        path: 'edit-guide-master',
        loadChildren: './forms/edit-guide-master/edit-guide-master.module#EditGuideMasterModule'
    },
    {
        path: 'list-reports',
        loadChildren: './forms/list-delivery-report/list-delivery-report.module#ListDeliveryReportModule'
    },
    {
        path: 'generate-excel-guides',
        loadChildren: './forms/generate-excel-delivery/generate-excel-delivery.module#GenerateExcelDeliveryModule'
    },
    {
        path: 'load-excel',
        loadChildren: './forms/load-company-excel/load-company-excel.module#LoadCompanyExcelModule'
    },
    {
        path: 'generate-invoice-enterprise',
        loadChildren: './forms/generate-invoice-enterprise/generate-invoice-enterprise.module#GenerateExcelDeliveryModule'
    },
    {
        path: 'trackingAWB',
        loadChildren: './forms/tracking-awb/tracking-awb.module#TrackingAWBModule'
    },
    {
        path: 'list-companies',
        loadChildren: './forms/list-enterprise/list-enterprise.module#ListEnterpriseModule',
    },
    {
        path: 'edit-services-by-enterprise/:id',
        loadChildren: './forms/edit-services-by-enterprise/edit-services-by-enterprise.module#EditServicesByEnterpriseModule'
    },
    {
        path: 'edit-enterprise/:id',
        loadChildren: './forms/edit-enterprise/edit-enterprise.module#EditEnterpriseModule'
    },
    {
        path: 'list-invoice',
        loadChildren: './forms/list-invoice/list-invoice.module#ListInvoiceModule'
    },
    {
        path: 'report-delivered-enterprise',
        loadChildren: './forms/report-delivered-enterprise/report-delivered-enterprise.module#ReportDeliveredEnterpriseModule'
    },
    {
        path: 'profile-client/:id',
        loadChildren: './forms/profileClient/profileClient.module#ProfileClientModule'
    },
    {
        path: 'report-delivered-agency',
        loadChildren: './forms/report-delivered-agency/report-delivered-agency.module#ReportDeliveredAgencyModule'
    },
    {
        path: 'consolidated-closed',
        loadChildren: './forms/consolidated-closed/consolidated-closed.module#ConsolidatedClosedModule'
    },
    {
        path: 'details-of-consolidated/:id',
        loadChildren: './forms/details-of-consolidated/details-of-consolidated.module#DetailsOfConsolidatedModule'
    },
    {
        path: 'list-master-awb',
        loadChildren: './forms/list-master-awb/list-master-awb.module#ListMasterAwbModule'
    },
    {
        path: 'apply-status-by-rol',
        loadChildren: './forms/apply-status-by-rol/apply-status-by-rol.module#ApplyStatusByRolModule'
    },
    {
        path: 'create-quote',
        loadChildren: './forms/create-quote/create-quote.module#CreateQuoteModule'
    },
    {
        path: 'list-quotation-request',
        loadChildren: './forms/list-quotation-request/list-quotation-request.module#ListQuotationRequestModule'
    },
    {
        path: 'list-user-quotation-request',
        loadChildren: './forms/list-user-quotation-request/list-user-quotation-request.module#ListUserQuotationRequestModule'
    },
    {
      path: 'global-search',
      loadChildren: './forms/global-search/global-search.module#GlobalSearchModule'
    },
    {
      path: 'generate-report-delivered-master',
      loadChildren: './forms/generate-report-delivered/generate-report-delivered.module#GenerateReportDeliveredModule'
    },
    {
      path: 'report-agencies-total',
      loadChildren: './forms/report-Agencies-total/report-Agencies-total.module#ReportAgenciesTotalModule'
    },
    {
      path: 'send-message',
      loadChildren: './forms/send-message/send-message.module#SendMessageModule'
    },
    {
      path: 'upload-testDelivery',
      loadChildren: './forms/upload-testDelivery/upload-testDelivery.module#UploadTestDeliveryModule'
    },
    {
      path: 'simple-guide',
      loadChildren: './forms/simple-guide/simple-guide.module#SimpleGuideModule'
    },
    {
      path: 'daily-report',
      loadChildren: './forms/daily-report/daily-report.module#DailyReportModule'
    },
    {
      path: 'guides-to-delivering-master',
      loadChildren: './forms/guides-to-delivering-master/guides-to-delivering-master.module#GuidesToDeliveringMasterModule'
    },
    {
      path: 'massive-status-consolidate',
      loadChildren: './forms/massive-status-consolidate/massive-status-consolidate.module#MassiveStatusConsolidateModule'
    },
    {
      path: 'audit-status-guide',
      loadChildren: './forms/audit-status-guide/audit-status-guide.module#AuditStatusGuideModule'
    },
    {
      path: 'edit-awb-master/:code',
      loadChildren: './forms/edit-awb-master/edit-awb-master.module#EditAwbMasterModule'
    },
    {
      path: 'list-pqr',
      loadChildren: './forms/list-pqr/list-pqr.module#ListPqrModule'
    },
    {
      path: 'details-pqr/:code',
      loadChildren: './forms/pqr-details/pqr-details.module#PqrDetailsModule'
    },
    {
      path: 'register-tracking-warehouse',
      loadChildren: './forms/register-tracking-warehouse/register-tracking-warehouse.module#RegisterTrackingWarehouseModule'
    },
    {
        path: 'report-tracking-warehouse',
        loadChildren: './forms/report-tracking-warehouse/report-tracking-warehouse.module#ReportTrackingWarehouseModule'
    },
    {
        path: 'edit-template-quickbooks',
        loadChildren: './forms/edit-template-quickbooks/edit-template-quickbooks.module#EditTemplateQuickbooksModule'
    },
];
